<template>
<div id="app">
    <div id="content" class="content">
        <b-modal id="modal_banner" centered hide-footer>
            <a href="https://www.facebook.com/NovelRealm/photos/a.227627431238609/962056634462348">
                <b-img src="https://i.imgur.com/JE49FOX.jpg" fluid></b-img>
            </a>
        </b-modal>
        <Navbar v-if="!GET_IS_WRITER_MODE" />
        <MenuBar v-if="!GET_IS_WRITER_MODE" />
        <ControlPanel v-if="GET_IS_WRITER_MODE" />
        <router-view v-if="!GET_IS_WRITER_MODE"></router-view>
    </div>
    <Footer class="footer" v-if="!GET_IS_WRITER_MODE" />
    <Cookie />
</div>
</template>

<script>
import 'ant-design-vue/dist/antd.css';
import Navbar from './components/navbar.vue'
import MenuBar from './components/MenuBar.vue'
import Footer from './components/elements/Footer'
import ControlPanel from './components/pages/ControlPanel'
import axios from 'axios'
import router from './router/index'
import {
    mapActions,
    mapGetters
} from 'vuex'
import Cookie from './components/elements/Cookie.vue';

var queryParams = new URLSearchParams(window.location.search);
export default {
    name: 'App',
    data() {
        return {
            userInfo: JSON.parse(localStorage.getItem('UserInfo')),
            writerControl: false,
            isLogin: localStorage.getItem('UserInfo') != null,
            interval_id: null,
            prevType: '',
            loggingout: false,
            time_out: null,
            Modal_banner: localStorage.getItem('Banner_2022_06') != null
        }
    },
    computed: {
        ...mapGetters(['GET_LOADING', 'GET_IS_WRITER_MODE', 'GET_MODE', 'GET_DEVICE_INFO']),
        
    },
    components: {
    Navbar,
    MenuBar,
    Footer,
    ControlPanel,
    Cookie
},
    watch: {
        $route(to, from) {
            if (this.isLogin) {
                this.getdevice()
                this.workerupdateUser()
            }
            if (from.path.indexOf('Control') != 1 && to.path.indexOf('Control') == 1) {
                location.reload()
            }

            //if api or sth error
            setTimeout(function () {
                this.load_timeout()
            }.bind(this),3000)
        },
        GET_MODE() {
            this.checkMode()
        }
    },
    mounted() {
        if (localStorage.getItem('mode') == null) {
            this.SET_MODE('lightmode')
        }

        const currentDate = new Date()
        const expireDate = new Date("2022-06-17")
        if (this.Modal_banner == false && currentDate.getTime() <= expireDate.getTime()){
            this.$bvModal.show('modal_banner')
            localStorage.setItem("Banner_2022_06", true);
        }

        this.checkMode()
        if (this.isLogin) {
            this.SET_DEVICE_INFO(JSON.parse(localStorage.getItem('DeviceInfo')))
            this.workerupdateUser()
            this.first_commit()
            
            window.addEventListener("blur", this.device_commit)
            window.addEventListener("focus", this.device_commit)
        }
        if (window.location.pathname.indexOf('login') != -1) {
            this.loginWithFb()
        } else if (window.location.pathname.indexOf('profile&success') != -1) {
            queryParams.set("menu", "history");
            history.replaceState(null, null, "?" + queryParams.toString());
            window.location.pathname = "/profile"
        }

        this.changeMode()
        //ถ้าค่าที่ใช้ login ไม่เป็น null แสดงว่า login ไว้แล้ว ให้เซ็ท loginStatus ใน vuex เป็น true
        if (localStorage.getItem('UserInfo') != null) {
            this.SET_USER_INFO(JSON.parse(localStorage.getItem('UserInfo')))
            this.SET_LOGIN_STATUS(true)
            
            //Set notification
            if(this.GET_NOTI_TIMESTAMP != this.GET_LAST_NOTI_TIMESTAMP){
                this.$notification['info'](
                    this.GET_NOTI_MSG
                );
                this.SET_NOTI_TIMESTAMP()
            }
        }
        if(this.GET_LOADING == true){
            setTimeout(function () {
                this.load_timeout()
            }.bind(this),3000)
        }
    },
    methods: {
        ...mapActions(['SET_DEVICE_INFO', 'SET_LOADING_STATUS', 'SET_MEMBER_GOLD', 'SET_LOGIN_STATUS', 'SET_USER_INFO', 'SET_IS_WRITER_MODE', 'SET_MODE', 'SET_MEMBER_PROFILE_URL', 'SET_DISPLAYNAME']),
        img_load_default_cover(event){
            //console.log("Image broken -> Load default")
            event.target.src = require("@/assets/default_cover.jpg")
        },
        load_timeout(){
            if(this.GET_LOADING == true){
                this.SET_LOADING_STATUS(false)
            }
        },
        async loginWithFb() {
            var token = window.location.pathname.substring((window.location.pathname).lastIndexOf("/") + 1)
            const formData = new FormData();
            formData.append('token', token);
            // router.push("/")
            try {
                await axios.post('https://api2.novelrealm.com/module/facebook&submodule=token', formData)
                    .then((res) => {
                        //console.log(res.data)
                        if (res.data.status == 200) {
                            this.SET_USER_INFO({
                                auth_token: res.data.auth_reference,
                                auth_memberid: res.data.auth_memberid,
                                displayname: res.data.member_displayname,
                                member_gold: res.data.member_gold,
                                member_profile_url: res.data.member_profile_url,
                                member_is_admin: res.data.member_is_admin,
                                member_is_publisher: res.data.member_is_publisher
                            })
                            this.SET_LOGIN_STATUS(true);
                        } else if (res.data.status == 404) {
                            alert('cannot login')
                        } else if (res.data.status == 403) {
                            alert('บัญชีของคุณถูกระงับ กรุณาติดต่อเจ้าหน้าที่ที่เพจเฟสบุ๊ก')
                        }
                        router.push("/")
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        changeMode() { //สำหรับเช็คว่าเป็น readerหรือwriter
            if (window.location.pathname.indexOf('Control') != -1) {
                this.SET_IS_WRITER_MODE(true)
                //console.log('GET_IS_WRITER_MODE')
            } else {
                this.SET_IS_WRITER_MODE(false)
            }
        },
        checkMode() { //lightmode,darkmode
            if (this.GET_MODE == 'darkmode') {
                this.darkmode()
            } else {
                this.lightmode()
            }
        },
        lightmode() {
            document.getElementById('content').classList.remove('darkmode')
        },
        darkmode() {
            document.getElementById('content').classList.add('darkmode')
        },
        async workerupdateUser() {
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            try {
                await axios.post('https://api2.novelrealm.com/module/profile&submodule=info', formData)
                    .then((res) => {
                        //console.log(res.data)
                        if (res.data.status == 200) {
                            this.SET_USER_INFO({
                                auth_token: this.userInfo.auth_token,
                                auth_memberid: this.userInfo.auth_memberid,
                                displayname: res.data.member_displayname,
                                member_gold: res.data.member_gold,
                                member_profile_url: res.data.member_profile_url,
                                member_is_admin: res.data.member_is_admin,
                                member_is_publisher: res.data.member_is_publisher
                            })
                        }
                        else if(res.data.status == "403" && res.data.description == "Banned"){
                            if(this.loggingout == false){
                                this.loggingout = true
                                this.logout()
                            }
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        async getdevice(){
            console.log(this.GET_DEVICE_INFO)
            const formData = new FormData();
            formData.append('auth_memberid', this.userInfo.auth_memberid);
            formData.append('auth_reference', this.userInfo.auth_token);
            if(this.GET_DEVICE_INFO != null){
                formData.append('device_token', this.GET_DEVICE_INFO.device_token);
            }
            try {
                await axios.post('https://api2.novelrealm.com/module/device', formData)
                    .then((res) => {
                        if(res.data.status == "202" || res.data.status == "201"){
                            this.SET_DEVICE_INFO(res.data)
                        }
                        if(res.data.status == "403"){
                            //alert("Logout!!")
                            if(this.loggingout == false){
                                this.SET_DEVICE_INFO({})
                                this.loggingout = true
                                this.logout()
                            }
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        },
        device_commit(e){
            //console.log("COMMIT_DEVICE")
            if (this.prevType != e.type) {   //  reduce double fire issues
                switch (e.type) {
                    case "blur":
                        //console.log("Blur");
                        clearInterval(this.interval_id);
                        this.interval_id = null
                        break;
                    case "focus":
                        //console.log("Focus");
                        //console.log(this.interval_id)
                        if(this.interval_id==null){
                            this.getdevice();
                            this.interval_id = setInterval(this.getdevice,5000);
                        }
                        break;
                }
            }
            this.prevType = e.type
        },
        first_commit(){
            //console.log("INITIAL_DEVICE")
            this.interval_id = setInterval(this.getdevice,5000);
        },
        logout() {
            let keysToRemove = ["UserInfo", "LoginStatus"];
            if (!localStorage.getItem("AutoLogin")) {
                keysToRemove.push("Username")
                keysToRemove.push("Password")
            }
            if(localStorage.getItem("device_token")){
                keysToRemove.push("device_token")
            }
            keysToRemove.forEach(k => localStorage.removeItem(k))
            router.push("/");
            location.reload()
        },
    }
}
</script>

<style>
#app {
    color: #505050;
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* height: 100%; */
}

.content {
    flex: 1 0 auto;
    background-color: #D4E6ED;
}

.darkmode {
    color: #ffffff;
    background-color: #000000;
}

.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.pageScope {
    padding: 3% 10% 2% 10%;
}

/* pill in AllNovelList.vue */
.page-item.active .page-link {
    color: #ffffff !important;
    background-color: #F5D087 !important;
    border-color: #F5D087 !important;
}

.page-link {
    color: #F5D087 !important;
    background-color: #fff !important;
    border: 1px solid #dee2e6 !important;
}

.page-link:active {
    outline: 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.page-link:hover {
    font-weight: bold !important;
}

.modal-backdrop {
    opacity: 0.87 !important;
    backdrop-filter: blur(2px) !important;
}

.footer {
    flex-shrink: 0;
    background-color: pink;
}

.vld-overlay .vld-background {
    opacity: 1 !important;
}

.darkmode .ant-empty-normal{
    color: rgba(255, 255, 255, 0.25);
}

.darkmode .ant-tabs-tab{
    background-color: #35363A !important;
    border-color: #35363A !important;
    color: #eee;
}

.darkmode .ant-tabs-tab-active{
    background-color: #fff !important;
    border-color: #fff !important;
}

.ant-notification{
    z-index: 99999;
}
</style>

<style>
#appleid-signin svg{
    height: 35px;
}
</style>