import Vue from 'vue'
import App from './App.vue'
import router from './router'


import Axios from 'axios';
Vue.use(Axios)


import Vuex from 'vuex'
Vue.use(Vuex)


import { store } from './components/store/store'


import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
Vue.use(Antd);


// import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)


import 'vue-datetime/dist/vue-datetime.css'
import { Datetime } from 'vue-datetime';
Vue.component('datetime', Datetime);


import Toasted from 'vue-toasted';
Vue.use(Toasted)


import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)


import BackToTop from 'vue-backtotop'
Vue.use(BackToTop)


import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
Vue.filter('numFormat', numFormat(numeral));


import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);
Vue.loadScript("https://cdn.omise.co/omise.js")
  .then(() => {
    //console.log('load script', window.OmiseCard)
    // window.OmiseCard.configure({
    //   publicKey: 'pkey_5eywffnyf8c9a602aq0'
    // });
  })
  .catch(() => {
    //console.log('fail script')
  });

import VueScreenSize from 'vue-screen-size'
Vue.use(VueScreenSize)

import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
for (let rule in rules) {
  extend(rule, rules[rule]);
}
extend('required', {
  validate: value => !!value,
  message: 'กรุณากรอกข้อมูล'
});
extend('email', {
  message: 'อีเมลไม่ถูกต้อง'
});
extend('alpha', {
  message: 'ต้องมีตัวอักษรอย่างเดียว'
});
extend('alpha_num', {
  message: 'ต้องมีตัวอักษรหรือตัวเลข'
});
extend('numeric', {
  message: 'ต้องมีตัวเลข'
});
extend('confirmed', {
  message: 'รหัสผ่านไม่ตรงกัน'
});
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use( CKEditor );

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);
Vue.component('Loading', Loading)

import VueThailandAddress from 'vue-thailand-address'; 
import 'vue-thailand-address/dist/vue-thailand-address.css';
Vue.use(VueThailandAddress);

Vue.use(require('vue-moment'));
Vue.config.productionTip = false

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

new Vue({
  store: store,
  router,
  render: h => h(App),
}).$mount('#app')