<template>
<b-navbar class="navbarBoot" toggleable="lg" type="dark" variant="info" v-bind:sticky='true'>
    <b-modal v-model="showLoginModal" :hide-header="true" :hide-footer="true">
        <Login />
    </b-modal>
    <b-modal v-model="showRegisModal" :hide-header="true" :hide-footer="true">
        <Register />
    </b-modal>
    <!-- เปิดบนคอม -->
    <div v-if="$vssWidth>=1024" style="display: contents;">
        <router-link to="/">
            <b-navbar-brand class="mr-5 novelrealm">NOVELREALM</b-navbar-brand>
        </router-link>
        <b-nav-form>
            <b-form-input size="sm" class="searchInput mr-4 ml-4" v-model="search" placeholder="ค้นหา" @keypress.enter.prevent="searchNovel()"></b-form-input>
        </b-nav-form>
        <b-navbar-nav class="ml-auto">
            <b-nav-item v-if="!loginStatus" class="mr-3" @click="showLoginModal = true">
                <span class="navbarList">
                    <b-icon-box-arrow-in-right></b-icon-box-arrow-in-right>เข้าสู่ระบบ
                </span>
            </b-nav-item>
            <b-nav-item v-if="!loginStatus" class="mr-3" @click="showRegisModal = true">
                <span class="navbarList">สมัครสมาชิก</span>
            </b-nav-item>
            <!-- login แล้ว แสดง username -->
            <b-nav-item v-else-if="loginStatus" class="mr-3 username">
                <b-dropdown size="sm" variant="link" toggle-class="text-decoration-none" no-caret class="shadow">
                    <template v-slot:button-content>
                        <b-row>
                            <b-col class="pr-0">
                                <b-avatar style="background:none" v-if="userInfo.member_profile_url==''" :src="require('@/assets/no-avartar.jpg')" size="2.4rem"></b-avatar>
                                <b-avatar style="background:none" v-else :src="userInfo.member_profile_url" size="2.4rem"></b-avatar>
                            </b-col>
                            <b-col class="pl-2">
                                <span class="navbarList" v-if="userInfo.displayname.length <= 12">{{userInfo.displayname}}</span>
                                <span class="navbarList" v-else>{{userInfo.displayname.substr(0,10)+"..."}}</span>
                                <br>
                                <img src="./../assets/price.png" width="13px" class="mycoinImg" /><span class="navbarList mycoin ml-1">{{userInfo.member_gold | numFormat }}</span>
                            </b-col>
                        </b-row>
                    </template>
                    <b-dropdown-item to="/profile">
                        <b-icon-person class="mr-1"></b-icon-person>ข้อมูลส่วนตัว
                    </b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item v-if="mode=='darkmode'" @click="modeChanged('lightmode')">
                        <b-icon-sun class="mr-1"></b-icon-sun>โหมดกลางวัน
                    </b-dropdown-item>
                    <b-dropdown-item v-if="mode=='lightmode'" @click="modeChanged('darkmode')">
                        <b-icon-moon class="mr-1"></b-icon-moon>โหมดกลางคืน
                    </b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item v-if="loginStatus" @click="gotowriter">
                        <b-icon-pen class="mr-1"></b-icon-pen>แผงควบคุมนักเขียน
                    </b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item v-if="userInfo.member_is_publisher == '1' || userInfo.member_is_admin == '1'" @click="gotopublisher">
                        <b-icon-book class="mr-1"></b-icon-book>แผงควบคุมสำนักพิมพ์
                    </b-dropdown-item>
                    <b-dropdown-divider v-if="userInfo.member_is_publisher == '1' || userInfo.member_is_admin == '1'"></b-dropdown-divider>
                    <b-dropdown-item v-if="userInfo.member_is_admin == '1'" @click="gotoadmin">
                        <b-icon-tools class="mr-1"></b-icon-tools>แผงควบคุม
                    </b-dropdown-item>
                    <b-dropdown-divider v-if="userInfo.member_is_admin == '1'"></b-dropdown-divider>
                    <b-dropdown-item @click="logout">
                        <b-icon-box-arrow-right class="mr-1"></b-icon-box-arrow-right>ออกจากระบบ
                    </b-dropdown-item>
                </b-dropdown>
            </b-nav-item>
        </b-navbar-nav>
    </div>

    <!-- เปิดบน tablet -->
    <div v-else class="navWidth">
        <b-container class="bv-example-row" style="paddingTop:5px;height: 2.5rem;">
            <b-row class="navWidth">
                <b-col cols="3" class="text-left">
                    <b-navbar-toggle target="searching">
                        <b-icon icon="search" font-scale="1.2" style="color: #ffffff;"></b-icon>
                    </b-navbar-toggle>
                </b-col>
                <b-col cols="7" class="text-center">
                    <b-navbar-brand to="/" class="novelrealm">NOVELREALM</b-navbar-brand>
                </b-col>
                <b-col cols="2" class="hamburger">
                    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
                </b-col>
            </b-row>
        </b-container>
        <b-collapse id="searching" class="mt-1 mb-1 ml-4 mr-4">
            <b-form-input size="sm" class="searchInput" v-model="search" placeholder="ค้นหา" @keypress.enter.prevent="searchNovel()"></b-form-input>
        </b-collapse>
        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="text-center navbarList">
                <b-nav-item v-if="!loginStatus" @click="showLoginModal = true"><span class="navbarList">เข้าสู่ระบบ</span></b-nav-item>
                <b-nav-item v-if="!loginStatus" @click="showRegisModal = true"><span class="navbarList">สมัครสมาชิก</span></b-nav-item>
                <b-nav-item v-if="loginStatus">
                    <b-row>
                        <b-col class="text-right pr-0">
                            <b-avatar style="background:none" v-if="userInfo.member_profile_url==''" :src="require('@/assets/no-avartar.jpg')" size="2.4rem"></b-avatar>
                            <b-avatar style="background:none" v-else :src="userInfo.member_profile_url" size="2.4rem"></b-avatar>
                        </b-col>
                        <b-col class="text-left pl-2">
                            <span class="navbarList">{{userInfo.displayname.substr(0,10)+"..."}}</span><br>
                            <img src="./../assets/price.png" width="13px" class="mycoinImg" /><span class="navbarList mycoin ml-1">{{userInfo.member_gold}}</span>
                        </b-col>
                    </b-row>
                </b-nav-item>

                <b-nav-item v-if="loginStatus" to="/profile"><span class="navbarList">ข้อมูลส่วนตัว</span></b-nav-item>
                <b-nav-item v-if="mode=='darkmode'" @click="modeChanged('lightmode')"><span class="navbarList">โหมดกลางวัน</span></b-nav-item>
                <b-nav-item v-if="mode=='lightmode'" @click="modeChanged('darkmode')"><span class="navbarList">โหมดกลางคืน</span></b-nav-item>
                <b-nav-item v-if="loginStatus" @click="gotowriter"><span class="navbarList">แผงควบคุมนักเขียน</span></b-nav-item>
                <b-nav-item v-if="userInfo.member_is_publisher == '1' || userInfo.member_is_admin == '1'" @click="gotopublisher"><span class="navbarList">แผงควบคุมสำนักพิมพ์</span></b-nav-item>
                <b-nav-item v-if="userInfo.member_is_admin == '1'" @click="gotoadmin"><span class="navbarList">แผงควบคุม</span></b-nav-item>
                <b-nav-item v-if="loginStatus" @click="logout"><span class="navbarList">ออกจากระบบ</span></b-nav-item>
                <b-dropdown-divider class="divider"></b-dropdown-divider>
                <b-nav-item href="#" @click="goToOtherPage(0)">
                    <b-icon class="mr-2" icon="pencil-square" font-scale="0.9" style="color: #ffffff;"></b-icon><span class="navbarList">ลงผลงานนักเขียน</span>
                </b-nav-item>
                <b-nav-item href="#" @click="goToOtherPage(1)">
                    <img class="mr-2" src="./../assets/facebook.png" width="15px" /><span class="navbarList">Facebook</span></b-nav-item>
                <b-nav-item href="#" @click="goToOtherPage(2)">
                    <b-icon class="mr-2" icon="chat-square" font-scale="0.9" style="color: #ffffff;"></b-icon><span class="navbarList">ชุมชนชาวยุทธ</span>
                </b-nav-item>
                <b-nav-item href="#" @click="goToOtherPage(3)">
                    <b-icon class="mr-2" icon="volume-up" font-scale="0.9" rotate="-45" style="color: #ffffff;"></b-icon><span class="navbarList">ร้องทุกข์ชาวยุทธ</span>
                </b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </div>
</b-navbar>
</template>

<script>
const Login = resolve => {
    require.ensure(['./pages/Login'], () => {
        resolve(require('./pages/Login.vue'))
    })
}
const Register = resolve => {
    require.ensure(['./pages/Register'], () => {
        resolve(require('./pages/Register.vue'))
    })
}
import router from './../router/index'
import {
    mapGetters,
    mapActions
} from 'vuex'

export default {
    name: "Navbar",
    data() {
        return {
            showLoginModal: false,
            showRegisModal: false,
            search: ""
        }
    },
    components: {
        Login,
        Register
    },
    computed: {
        ...mapGetters({
            loginStatus: 'GET_LOGIN_STATUS',
            userInfo: 'GET_USER_INFO',
            keyword: 'GET_KEYWORD',
            mode: 'GET_MODE',
            admin_domain: 'GET_ADMIN_DOMAIN',
            writer_domain: 'GET_WRITER_DOMAIN',
            publisher_domain: 'GET_PUBLISHER_DOMAIN'
        })
    },
    methods: {
        ...mapActions(['SET_KEYWORD', 'SET_SEARCH_NOVEL_WORD', 'SET_LOADING_STATUS', 'SET_MODE']),
        searchNovel() {
            this.SET_LOADING_STATUS(true)
            var queryParams = new URLSearchParams(window.location.search);
            this.SET_KEYWORD(this.search)
            this.SET_SEARCH_NOVEL_WORD(this.search)
            if (window.location.pathname.includes('/search')) {
                queryParams.set("search", this.search);
                history.replaceState(null, null, "?" + queryParams.toString());
            } else {
                router.push('/search')
            }
            this.search = ""
        },
        logout() {
            let keysToRemove = ["UserInfo", "LoginStatus"];
            if (!localStorage.getItem("AutoLogin")) {
                keysToRemove.push("Username")
                keysToRemove.push("Password")
            }
            if(localStorage.getItem("device_token")){
                keysToRemove.push("device_token")
            }
            keysToRemove.forEach(k => localStorage.removeItem(k))
            location.reload()
        },
        goToOtherPage(index) {
            if (index == 0) {
                router.push("/Control/manageNovel")
            } else if (index == 1) {
                window.open("https://www.facebook.com/NovelRealm", '_blank');
            } else if (index == 2) {
                window.open("https://www.facebook.com/groups/novelrealm", '_blank');
            } else if (index == 3) {
                window.open("https://www.facebook.com/groups/novelrealm/permalink/635543156908422", '_blank');
            }
        },
        modeChanged(m){
            this.SET_MODE(m)
            // location.reload()
        },
        gotowriter(){
            if(this.loginStatus == true){
                window.open(this.writer_domain+"/?ref1="+this.userInfo.auth_token+"&ref2="+this.userInfo.auth_memberid, '_blank');
            }
            else{
                this.showLoginModal = true
            }
        },
        gotoadmin(){
            if(this.loginStatus == true){
                if(this.userInfo.member_is_admin == "1"){
                    window.open(this.admin_domain+"/?ref1="+this.userInfo.auth_token+"&ref2="+this.userInfo.auth_memberid, '_blank');
                }
                else{
                    alert("Permission denied")
                }
            }
            else{
                this.showLoginModal = true
            }
        },
        gotopublisher(){
            if(this.loginStatus == true){
                if(this.userInfo.member_is_admin == "1" || this.userInfo.member_is_publisher == "1"){
                    window.open(this.publisher_domain+"/?ref1="+this.userInfo.auth_token+"&ref2="+this.userInfo.auth_memberid, '_blank');
                }
                else{
                    alert("Permission denied")
                }
            }
            else{
                this.showLoginModal = true
            }
        }
    }
}
</script>

<style scoped>
.dropdown.shadow>>>.dropdown-toggle {
    box-shadow: none !important;
}

.navWidth {
    width: 100%;
}

.navbarBoot {
    padding: 0.5% 8.9%;
    background-color: #042446 !important;
}

li.form-inline {
    width: 50% !important;
}

form.form-inline,
.searchInput {
    width: 100% !important;
}

.searchInput,
.searchInput:focus {
    border-radius: 8px;
    background-color: transparent;
}

.searchInput:focus {
    background-color: transparent;
    color: #ffffff;
    border-color: #ffffff;
    box-shadow: none
}

button.navbar-toggler,
button.navbar-toggler:focus {
    border-color: transparent;
    outline: none;
}

.novelrealm {
    font-size: 20px;
    font-weight: bold;
    color: #ffffff;
}

.novelrealm:hover {
    cursor: pointer;
}

.navbarList {
    color: #ffffff !important;
    font-size: 16px;
}

.mycoin {
    font-size: 14px;
}

.mycoinImg {
    padding-bottom: 3px;
}

.divider {
    padding: 0% 5%;
}

.addCoin {
    font-weight: bold;
    padding: 5px 7px 5px 20px;
    border: 2px solid #ffffff;
    border-radius: 8px;
}

.addCoinnotext{
    font-weight: bold;
    padding: 5px 7px 5px 20px;
}

.coinIconPosition {
    position: relative;
    z-index: 10;
    right: -1em;
}

.username>.nav-link {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-top: -4px;
    margin-bottom: -4px;
}

@media screen and (max-width: 768px) {
    .novelrealm {
        font-size: 17px;
        font-weight: bold;
        margin-left: -62px;
    }

    .navbarBoot {
        padding: 0.5% 0% 0.5% 0%;
    }

    .hamburger {
        position: absolute;
        right: 10px;
        top: 2px;
    }
}

@media screen and (max-width: 500px) {
    .novelrealm {
        margin-left: -10px;
    }

    .navbarList {
        color: #ffffff !important;
        font-size: 13px;
    }
}

@media screen and (min-width: 1000px) {
    .navbarBoot {
        padding: 0.5% 1%;
    }
}

@media screen and (min-width: 1480px) {
    .navbarBoot {
        padding: 0.5% 15%;
    }
}
</style>
