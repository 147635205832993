<template>
<b-navbar class="menuBar" toggleable="lg" type="dark" variant="info">
    <b-container class="bv-example-row">
        <b-col :id="'menu'+index" class="menuList text-center" v-for="(menu,index) in menuList" :key="index" @click="choosedMenu(index)">
            <span :id="'ab'+index">{{menu.menu}}</span>
        </b-col>
    </b-container>
</b-navbar>
</template>

<script>
import {
    mapActions
} from 'vuex'
import router from '../router'

export default {
    name: "MenuBar",
    data() {
        return {
            pathname: window.location.pathname,
            menuList: [{
                    menu: "หน้าแรก",
                    linkTo: "/"
                },
                {
                    menu: "นิยายทั้งหมด",
                    linkTo: "/allNovel"
                },
                {
                    menu: "นักเขียน",
                    linkTo: "/writer"
                },
                {
                    menu: "ติดต่อเรา",
                    linkTo: "/contact"
                }
            ]
        }
    },
    watch: {
        $route(to, from) {
            // Do only diff path
            if(from.path !== to.path){
                this.SET_HISTORY_PATH(from.path)
                this.pathname = to.path
                this.watchMenu()
            }
        }
    },
    mounted() {
        this.defaultMenuStyle()
        this.watchMenu()
    },
    methods: {
        ...mapActions(['SET_HISTORY_PATH']),
        watchMenu() {
            if (this.pathname == "/") {
                this.choosedMenu(0)
            } else if (this.pathname.includes("/allNovel")) {
                this.choosedMenu(1)
            } else if (this.pathname.includes("/writer")) {
                this.choosedMenu(2)
            } else if (this.pathname.includes("/contact")) {
                this.choosedMenu(4)
            } else {
                this.defaultMenuStyle()
            }
        },
        choosedMenu(index) {
            this.defaultMenuStyle()
            document.getElementById('menu' + index).classList.add("menuListActive")
            document.getElementById('ab' + index).style.color = "#042446"
            router.push(this.menuList[index].linkTo)
        },
        defaultMenuStyle() {
            for (var n = 0; n < this.menuList.length; n++) {
                document.getElementById('menu' + n).classList.remove("menuListActive")
                document.getElementById('ab' + n).style.color = "#505050"
            }
        }
    }
}
</script>

<style scoped>
.menuBar {
    padding: 0.5% 25% 0.5% 25%;
    background-color: #ffffff !important;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
    height: 80px;
}

.menuList a {
    text-decoration: none;
}

.menuList:hover {
    text-decoration: none;
    border-radius: 7px;
    padding-top: 5px;
    padding-bottom: 5px;
    cursor: pointer;
    font-weight: bold;
}

.menuListActive {
    text-decoration: none;
    border-radius: 7px;
    background-color: rgba(212, 230, 237, 0.78);
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
}

@media screen and (max-width: 768px) {
    .menuBar {
        padding: 0.5% 10% 0.5% 10%;
        font-size: 15px;
        height: 40px
    }
}

@media screen and (max-width: 500px) {
    .menuBar {
        padding: 0.5% 5% 0.5% 5%;
        font-size: 13px;
        height: 30px
    }

    .menuList,
    .menuList:hover,
    .menuListActive {
        padding: 0px;
        text-decoration: none;
        background-color: transparent;
        font-weight: bold;
    }
}
</style>
