export const notificationconfig = {
    state: {
        noti_timestamp: localStorage.getItem('notification_timestamp'),
        noti_last_timestamp: "20200105_1545",
        noti_msg:{
            message: 'แจ้งการเปลี่ยนแปลงเลขบัญชี',
            description:'ช่องทางการชำระเงินผ่านธนาคารมีการเปลี่ยนแปลงเลขบัญชีจาก 193-243874-9 เป็น 193-260227-5 กรุณาตรวจสอบก่อนทำการโอน',
            duration: 6
        }
    },
    getters: {
        GET_NOTI_TIMESTAMP(state) {
            return state.noti_timestamp
        },
        GET_LAST_NOTI_TIMESTAMP(state) {
            return state.noti_last_timestamp
        },
        GET_NOTI_MSG(state){
            return state.noti_msg
        }
    },
    mutations:{
        SET_NOTI_TIMESTAMP(){
            this.state.noti_timestamp = "20200105_1545"
            localStorage.setItem('notification_timestamp', '20200105_1545')
        }
    },
    actions: {
        SET_NOTI_TIMESTAMP({ commit }){
            commit('SET_NOTI_TIMESTAMP')
        }
    }
}